.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto;
    max-width: 1320px;
    font-size: 16px;
    background-color: #f1f2f5;
    padding: 0 20px;
}

.main h1, .main h2{
    text-align: center;
}


.main h1{
    font-size: 32px;
    margin-bottom: 25px;
}

.main h2{
    font-size: 20px;
    font-weight: 400;
    margin-top: 35px;
    margin-bottom: 22px;
}

.main p {
    margin-bottom: 20px;
}

.main ul {
    margin-left: 70px;
}

.textSmall {
    font-size: 14px;
}
