.error_input {
    color: #ff0000 !important;
}

.input_field {
    min-width: 100px;
    width: 100%;
    max-width: 250px;
    font-family: 'Microsoft JhengHei UI Light', sans-serif !important;
    font-size: 16px !important;
}

.checkboxLabel {
    cursor: pointer;
    font-size: 13px;
    font-family: 'Microsoft JhengHei UI Light', sans-serif !important;
}

.submitButton {
    margin-top: 8px;
    font-size: 16px !important;
    line-height: 0 !important;
    font-weight: normal !important;
    font-family: "Arial", sans-serif !important;
    text-transform: capitalize !important;
}

.error_sign {
    color: red;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
}

.error_text {
    color: black;
    font-size: 12px;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    padding-inline: 20px;
    display: inline-flex;
    text-align: center;
    border-color: #DD6161;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
    justify-content: center;
}