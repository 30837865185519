.helpTitle {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 20px !important;
    margin-top: 20px !important;
}

.mainTitle {
    /*font-family: 'Amatic SC', serif !important;*/
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 30px !important;
    font-weight: bold !important;
    text-align: center;
}

.vacancyName {
    font-size: 20px !important;
    font-family: 'Source Code Pro', sans-serif !important;
}

.vacancyDescription {
    font-family: 'Arial', sans-serif !important;
}

.vacancyButton {
    font-family: "Arial", sans-serif !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    margin-top: 10px;
}