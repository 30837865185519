.app_bar {
    height: 50px;
    width: 100%;
    color: inherit;
    position: static !important;
}

.tool_bar {
    padding-right: 0 !important;
}

.logo_text {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 20px !important;
    color: inherit;
}

.logo_image {
    padding-top: 5px;
    height: 170px;
    width: auto;
    object-fit: cover;
}