.productImage {
    height: auto;
    max-width: 200px;
    width: auto;
    border-radius: 4%;
}

.productTitle {
    color: #444444;
    font-size: 18px !important;
    max-width: 200px;
    font-family: 'Nunito Sans', sans-serif !important;
}

.productCost {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: bold !important;
}