.phone {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 20px !important;
    text-align: center;
}

.email {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 18px !important;
    text-align: center;
    padding-left: 10px;
}

.title {
    font-family: 'Nunito Sans', sans-serif !important;
    font-weight: bold !important;
}