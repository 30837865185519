.input_field {
    max-width: 300px;
    font-size: 14px !important;
    text-overflow: ellipsis !important;
}

.tabText {
    font-size: 14px !important;
    color: black !important;
}

.mt40 {
    margin-top: 40px;
}

@media screen and (max-width: 660px) {
    .lastTab {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 400px) {
    .lastTab {
        transform: scale(0.65);
    }
}

.lastTabSum {
    font-width: bold !important;
    font-family: 'Nunito Sans', sans-serif !important;
}