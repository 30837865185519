.mainImage {
    height: 280px;
    width: 280px;
    object-fit: cover;
}


.contentFont {
    font-family: 'Source Sans Pro', sans-serif !important;
}

.decorativeAmaticTitle {
    font-family: 'Amatic SC', serif !important;
}
