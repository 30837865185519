.link {
    color: #777777;
    font-family: "Arial", sans-serif;
    font-size: 17px;
    line-height: 20px;
}

.link:hover {
    text-decoration: underline !important;
}

.lastLink {
    font-family: "Arial", sans-serif !important;
    font-size: 17px !important;
    line-height: 20px;
    color: black !important;
}

.breadcrumbs {
    padding-top: 20px;
}