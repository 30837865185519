.ql-align-right {
    text-align: right;
}

.ql-align-center {
    text-align: center;
}

.ql-align-left {
    text-align: left;
}

.white {
    background-color: white;
}

.center {
    text-align: center;
}

input[placeholder] {
    text-overflow: ellipsis;
}