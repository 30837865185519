.ourPlusesContent {
    padding-top: 15px;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-size: 17px !important;
}

.ourPlusesGrid {
    gap: 5px;
    display: grid;
}

.outPlusesListTitle {
    text-align: center;
    font-size: 22px !important;
    margin-top: 20px !important;
    font-family: 'Nunito Sans', sans-serif !important;
    font-weight: bold !important;
}

.ourPlusesImage {
    margin-top: 0 !important;
    margin-inline: 0 !important;
    min-height: 270px;
    width: 100%;

    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.ourPlusesCard {
    max-width: 330px;
    height: 100%;
    box-shadow: 0 1px 2px #999999 !important;
}

.decorativeAmaticTitle {
    font-family: 'Amatic SC', serif !important;
}