.card {
    width: 240px;
    height: fit-content;
    min-height: 320px;
    margin: 20px 20px;
}

.card_media {
    width: 100% !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    object-fit: cover;

    height: 200px;

    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.card_content {
    padding: 0 0 0 0!important;
}

.card_content__text {
    font-size: 16px !important;
    text-align: center;
    font-family: "Nunito Sans", sans-serif !important;
    padding: 10px 10px;
}

.card_action_area {
    height: 320px;
    line-height: 0 !important;
}
