.mobile_header {
    width: auto !important;
    height: 50px;
}

.menu_button {
    height: 100%;
    min-width: 70px;
    cursor: pointer;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    display: flex;
    -webkit-tap-highlight-color: transparent;
}

.menu_button:hover {
    background-color: #c7c7c7;
}

.mobile_menu {
    width: 200px;
}