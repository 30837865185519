.title {
    font-size: 30px !important;
    font-family: 'Nunito Sans', sans-serif !important;
}


.content {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 20px ;

    width: 90%;
    max-width: 880px;
    margin: 60px auto;
    min-height: 300px;
}