.link_box {
    color: grey;
    height: 100%;
    align-self: center;
    display: inline-block;
}

.current_page_link {
    color: inherit;
}

.link_box:hover {
    color: #ff3333;
}

.link_text {
    text-align: center;
    font-size: 17px !important;
    font-family: "Helvetica Neue", sans-serif !important;
    line-height: 3 !important;
    padding-inline: 30px;
    color: inherit;
}